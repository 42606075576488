import React from 'react';
import { Link } from 'gatsby';
import SEO from '../components/SEO';
import Mailto from '../components/Mailto';
import Layout from '../components/Layout/Layout';
import Section from '../components/Section/Section';

const CGVPage: React.FC = () => (
  <Layout
    title="Conditions générales de ventes (CGV)"
    crumbs={[
      { name: 'Accueil', path: '/' },
      {
        name: 'Conditions générales de ventes',
        path: '/conditions-generales/',
      },
    ]}
  >
    <SEO
      title="Conditions générales de ventes | Poupée Odette"
      description="Conditions générales de vente pour poupeeodette.fr"
      keywords={['CGV']}
    />

    <Section>
      <h3>DÉFINITION DES PARTIES</h3>
      <p>
        La société peut être jointe par email sur{' '}
        <Mailto address="hello@poupeeodette.fr">hello@poupeeodette.fr</Mailto>{' '}
        accessible dans le menu via l’onglet "contactez-nous". Ci-après le «
        Vendeur » ou la « Société ». D’une part, Et la personne physique ou
        morale procédant à l’achat de produits ou services de la société,
        Ci-après, « l’Acheteur », ou « le Client » D’autre part,
      </p>
      <h3>PRÉAMBULE</h3>
      <p>
        Le Vendeur est éditeur de produits et services commercialisés par
        l’intermédiaire de son site Internet (https://poupeeodette.fr/). La
        liste et le descriptif des biens et services proposés par la Société
        peuvent être consultés sur le site susmentionné ainsi que ses pages de
        vente.
      </p>
      <h3>ARTICLE 1 – OBJET</h3>
      <p>
        Les présentes Conditions Générales de Vente déterminent les droits et
        obligations des parties dans le cadre de la vente en ligne de Produits
        et de Services proposés par le Vendeur.
      </p>
      <h3>ARTICLE 2 – DISPOSITIONS GÉNÉRALES</h3>
      <p>
        Les présentes Conditions Générales de Vente (CGV) s’appliquent à toutes
        les ventes de Produits, ou Services effectuées au travers du site
        Internet de la Société et sont partie intégrante du Contrat entre
        l’Acheteur et le Vendeur. Le Vendeur se réserve la possibilité de
        modifier les présentes, à tout moment par la publication d’une nouvelle
        version sur son site Internet. Les CGV applicables alors sont celles
        étant en vigueur à la date du paiement (ou du premier paiement en cas de
        paiements multiples) de la commande. Ces CGV sont consultables sur le
        site Internet de la Société à l’adresse suivante :{' '}
        <Link to="/">https://poupeeodette.fr</Link>. La Société s’assure
        également que leur acceptation soit claire et sans réserve au moment de
        l’achat. Le Client déclare avoir pris connaissance de l’ensemble des
        présentes Conditions Générales de Vente, et le cas échéant des
        Conditions Particulières de Vente liées à un produit ou à un service, et
        les accepter sans restriction ni réserve. Le Client reconnaît qu’il a
        bénéficié des conseils et informations nécessaires afin de s’assurer de
        l’adéquation de l’offre à ses besoins. Le Client déclare être en mesure
        de contracter légalement en vertu des lois françaises ou valablement
        représenter la personne physique ou morale pour laquelle il s’engage.
        Sauf preuve contraire les informations enregistrées par la Société
        constituent la preuve de l’ensemble des transactions.
      </p>
      <h3>ARTICLE 3 – PRIX</h3>
      <p>
        Les prix des produits vendus au travers du site Internet sont indiqués
        en Euros toutes taxes comprises et hors frais d’expédition sur les pages
        de descriptifs des Produits. A ce jour, l’expédition n’est pas possible
        hors France métropolitaine.
      </p>
      <p>
        Néanmoins, quand la livraison hors France métropolitaine sera disponible
        et pour tous les produits expédiés hors France métropolitaine, des
        droits de douane ou autres taxes locales ou droits d’importation ou
        taxes d’état sont susceptibles d’être exigibles dans certains cas. Ces
        droits et sommes ne relèvent pas du ressort du Vendeur. Ils seront à la
        charge de l’acheteur et relèvent de sa responsabilité (déclarations,
        paiement aux autorités compétentes, etc.). Le Vendeur invite à ce titre
        l’acheteur à se renseigner sur ces aspects auprès des autorités locales
        correspondantes. La Société se réserve la possibilité de modifier ses
        prix à tout moment pour l’avenir. Les frais de télécommunication
        nécessaires à l’accès au site Internet de la Société sont à la charge du
        Client. Le cas échéant également, les frais de livraison.
      </p>
      <h3>ARTICLE 4 – CONCLUSION DU CONTRAT EN LIGNE</h3>
      <p>
        Le Client devra suivre une série d’étapes spécifiques à chaque Produit
        ou Service offert à la vente par le Vendeur pour pouvoir réaliser sa
        commande. Toutefois, les étapes décrites ci-après sont systématiques : –
        Information sur les caractéristiques essentielles du Produit ; – Choix
        du Produit, le cas échéant de ses options et indication des données
        essentielles du Client (identification, adresse…) ; – Vérification des
        éléments de la commande et, le cas échéant, correction des erreurs. –
        Suivi des instructions pour le paiement, et paiement des produits. –
        Livraison des produits. Le Client recevra alors confirmation par
        courrier électronique du paiement de la commande avec accusé de
        réception de la commande la confirmant. Pour les produits livrés, cette
        livraison se fera à l’adresse indiquée par le Client dans l’adresse de
        facturation. Aux fins de bonne réalisation de la commande, le Client
        s’engage à fournir ses éléments d’identification véridiques. Le Vendeur
        se réserve la possibilité de refuser la commande, par exemple pour toute
        demande anormale, réalisée de mauvaise foi ou pour tout motif légitime.
      </p>
      <h3>ARTICLE 5 – PRODUITS ET SERVICES</h3>
      <p>
        Les caractéristiques essentielles des biens, des services et leurs prix
        respectifs sont mis à disposition de l’acheteur sur le site Internet de
        la société. Le client atteste avoir été informé des frais de livraison
        ainsi que les modalités de paiement et de livraison. Le Vendeur s’engage
        à honorer la commande du Client dans la limite des stocks disponibles
        uniquement. A défaut, le Vendeur en informe le Client. Ces informations
        contractuelles sont présentées en détail et en langue française.
        Conformément à la loi française, elles font l’objet d’un récapitulatif
        et d’une confirmation lors de la validation de la commande. Les parties
        conviennent que les illustrations ou photos des produits sur le site
        n’ont pas de valeur contractuelle. La durée de validité de l’offre des
        Produits ainsi que leurs prix est précisée sur les pages de ventes des
        produits. Sauf conditions particulières, les droits concédés au titre
        des présentes le sont uniquement à la personne physique signataire de la
        commande (ou la personne titulaire de l’adresse email communiqué).
        Conformément aux dispositions légales en matière de conformité et de
        vices cachés, le Vendeur rembourse ou échange les produits défectueux ou
        ne correspondant pas à la commande. Le remboursement ou l’échange peut
        être demandé en contactant le Vendeur à l’adresse suivante :{' '}
        <Mailto address="hello@poupeeodette.fr">hello@poupeeodette.fr</Mailto>{' '}
        qui se réserve le droit d’accepter. Les frais de port de retour sont
        offerts en France métropolitaine et dans le cadre d’un échange les
        nouveaux frais d’envois sont à la charge de l’Acheteur.
      </p>
      <h3>ARTICLE 6 – CLAUSE DE RESERVE DE PROPRIETE</h3>
      <p>
        Les produits demeurent la propriété de la Société jusqu’au complet
        paiement du prix.
      </p>
      <h3>ARTICLE 7 – MODALITÉS DE LIVRAISON</h3>
      <p>
        Les produits sont livrés à l’adresse de livraison qui a été indiquée
        lors de la commande (similaire à l’adresse de facturation) et le délai
        indiqué. Ce délai ne prend pas en compte le délai de préparation de la
        commande. Vous pouvez contacter le Vendeur sur l’adresse mail{' '}
        <Mailto address="hello@poupeeodette.fr">hello@poupeeodette.fr</Mailto>{' '}
        pour toute question ou le suivi de la commande. Le Vendeur rappelle
        qu’au moment où le Client prend possession physiquement des produits,
        les risques de perte ou d’endommagement des produits lui est transféré.
        Au moment de la livraison, il appartient au client de vérifier que le
        colis n’a pas été endommagé et le signaler au transporteur le cas
        échéant.
      </p>
      <h3>ARTICLE 8 – DISPONIBILITÉ ET PRÉSENTATION</h3>
      <p>
        Les commandes seront traitées dans la limite de nos stocks disponibles
        ou sous réserve des stocks disponibles chez nos fournisseurs.
      </p>
      <h3>ARTICLE 9 – PAIEMENT</h3>
      <p>
        Le paiement est exigible immédiatement à la commande, y compris pour les
        produits en précommande. Le Client peut effectuer le règlement par carte
        de paiement. Le paiement sécurisé en ligne par carte bancaire est
        réalisé par notre prestataire de paiement. Les cartes émises par des
        banques domiciliées hors de France doivent obligatoirement être des
        cartes bancaires internationales (Mastercard ou Visa). Une fois le
        paiement réalisé par le Client, la transaction est immédiatement débitée
        après vérification des informations. Conformément aux dispositions du
        Code monétaire et financier, l’engagement de payer donné par carte est
        irrévocable. En communiquant ses informations bancaires lors de la
        vente, le Client autorise le Vendeur à débiter sa carte du montant
        relatif au prix indiqué. Le Client confirme qu’il est bien le titulaire
        légal de la carte à débiter et qu’il est légalement en droit d’en faire
        usage. En cas d’erreur, ou d’impossibilité de débiter la carte, la Vente
        est immédiatement résolue de plein droit et la commande annulée.
      </p>
      <h3>ARTICLE 10 – DELAI DE RETRACTATION</h3>
      <p>
        Conformément à l’article L. 121-20 du Code de la consommation, « le
        consommateur dispose d’un délai de quatorze jours francs pour exercer
        son droit de rétractation sans avoir à justifier de motifs ni à payer de
        pénalités, à l’exception, le cas échéant, des frais de retour ». « Le
        délai mentionné à l’alinéa précédent court à compter de la réception des
        produits. Le droit de rétractation peut être exercé en contactant le
        Vendeur à l’adresse mail{' '}
        <Mailto address="hello@poupeeodette.fr">hello@poupeeodette.fr</Mailto>.
        En cas d’exercice du droit de rétractation dans le délai susmentionné,
        seul le prix du ou des produits achetés et les frais d’envoi seront
        remboursés, les frais de retour restent à la charge du Client. Les
        retours des produits sont à effectuer dans leur état d’origine et
        complets (emballage, …) de sorte qu’ils puissent être re-commercialisés
        à l’état neuf ; ils doivent si possible être accompagnés d’une copie du
        justificatif d’achat.
      </p>
      <h3>ARTICLE 11 – GARANTIES</h3>
      <p>
        Conformément à la loi, le Vendeur assume deux garanties : de conformité
        et relative aux vices cachés des produits. Le Vendeur rembourse
        l’acheteur ou échange les produits apparemment défectueux ou ne
        correspondant pas à la commande effectuée. La demande de remboursement
        doit s’effectuer en contactant le Vendeur par email à l’adresse{' '}
        <Mailto address="hello@poupeeodette.fr">hello@poupeeodette.fr</Mailto>.
        Le Vendeur rappelle que le consommateur : – dispose d’un délai de 2 ans
        à compter de la délivrance du bien pour agir auprès du Vendeur – qu’il
        peut choisir entre le remplacement et la réparation du bien sous réserve
        des conditions prévues par les dispositions susmentionnées. apparemment
        défectueux ou ne correspondant – qu’il est dispensé d’apporter la preuve
        l’existence du défaut de conformité du bien durant les six mois suivant
        la délivrance du bien. – que, sauf biens d’occasion, ce délai sera porté
        à 24 mois à compter du 18 mars 2016 – que le consommateur peut également
        faire valoir la garantie contre les vices cachés de la chose vendue au
        sens de l’article 1641 du code civil et, dans cette hypothèse, il peut
        choisir entre la résolution de la vente ou une réduction du prix de
        vente (dispositions des articles 1644 du Code Civil).
      </p>
      <h3>ARTICLE 12 – RÉCLAMATIONS</h3>
      <p>
        Le cas échéant, l’Acheteur peut présenter toute réclamation en
        contactant la société par email à l’adresse{' '}
        <Mailto address="hello@poupeeodette.fr">hello@poupeeodette.fr</Mailto>.
      </p>
      <h3>ARTICLE 13 – DROITS DE PROPRIÉTÉ INTELLECTUELLE</h3>
      <p>
        Les marques, noms de domaines, produits, logiciels, images, vidéos,
        textes ou plus généralement toute information objet de droits de
        propriété intellectuelle sont et restent la propriété exclusive du
        Vendeur. Aucune cession de droits de propriété intellectuelle n’est
        réalisée au travers des présentes CGV. Toute reproduction totale ou
        partielle, modification ou utilisation de ces biens pour quelque motif
        que ce soit est strictement interdite. Les produits eux-mêmes, en tant
        que créations originales, sont également protégés par les dispositions
        nationales et internationales sur les dessins et modèles et le droit
        d’auteur. La marque Poupée Odette est déposée à l’INPI ainsi que les
        dessins et modèles des produits.
      </p>
      <h3>ARTICLE 14 – FORCE MAJEURE</h3>
      <p>
        L’exécution des obligations du vendeur au terme des présentes est
        suspendue en cas de survenance d’un cas fortuit ou de force majeure qui
        en empêcherait l’exécution. Le vendeur avisera le client de la
        survenance d’un tel évènement dès que possible.
      </p>
      <h3>ARTICLE 15 – NULLITÉ ET MODIFICATION DU CONTRAT</h3>
      <p>
        Si l’une des stipulations du présent contrat était annulée, cette
        nullité n’entraînerait pas la nullité des autres stipulations qui
        demeureront en vigueur entre les parties. Toute modification
        contractuelle n’est valable qu’après un accord écrit et signé des
        parties.
      </p>
      <h3>ARTICLE 16 – PROTECTION DES DONNÉES PERSONNELLES</h3>
      <p>
        Conformément à la Loi Informatique et Libertés du 6 janvier 1978, vous
        disposez des droits d’interrogation, d’accès, de modification,
        d’opposition et de rectification sur les données personnelles vous
        concernant. En adhérant à ces conditions générales de vente, vous
        consentez à ce que nous collections et utilisions ces données pour la
        réalisation du présent contrat. En saisissant votre adresse email sur
        l’un des sites de notre réseau, vous recevrez des emails contenant des
        informations et des offres promotionnelles concernant des produits
        édités par le Vendeur. Vous pouvez vous désinscrire à tout instant. Il
        vous suffit pour cela de cliquer sur le lien présent à la fin de nos
        emails. Nous effectuons sur l’ensemble de nos sites un suivi de la
        fréquentation. Pour cela, nous avons recours à des outils tels que
        Google Analytics et Instagram Analytics.
      </p>
      <h3>ARTICLE 17 – DROIT APPLICABLE</h3>
      <p>
        Toutes les clauses figurant dans les présentes conditions générales de
        vente, ainsi que toutes les opérations d’achat et de vente qui y sont
        visées, seront soumises au droit français. En cas de litige, une
        tentative de résolution amiable du conflit, à défaut de quoi sera saisie
        la juridiction compétente.
      </p>
    </Section>
  </Layout>
);

export default CGVPage;
